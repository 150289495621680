import logo from './logo.svg';
import './App.css';
import Nav from './Components/Nav';

function App() {
  return (
    <div className="App">
      {/* <Nav /> */}
      <header className="App-header">
        So you have found my website... <br/>
        More to come
      </header>
    </div>
  );
}

export default App;
